import React from 'react';

import {
  IMonitoringContext,
  MonitoringContext,
  monitoringDefaultValue,
} from './MonitoringContext';
import { FCWithChildren } from '@wix/challenges-web-library';

export const MonitoringProvider: FCWithChildren<IMonitoringContext> = (
  props,
) => {
  return (
    <MonitoringContext.Provider
      value={{
        startInteraction:
          props.startInteraction || monitoringDefaultValue.startInteraction,
        endInteraction:
          props.endInteraction || monitoringDefaultValue.endInteraction,
      }}
    >
      {props.children}
    </MonitoringContext.Provider>
  );
};
