import React from 'react';
import { ListChallengesResponse } from '@wix/ambassador-challenges-v1-challenge/types';

export interface IChallengesListDataContext {
  challengesListData: ListChallengesResponse;
  programPageURI: string; // to build the right links from list to the certain program page
}

export const ChallengesListDataContext =
  React.createContext<IChallengesListDataContext>({
    challengesListData: null,
    programPageURI: null,
  });

export const useChallengesListData = () =>
  React.useContext(ChallengesListDataContext);

export const ChallengesListDataConsumer = ChallengesListDataContext.Consumer;
