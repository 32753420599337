import React from 'react';

export interface IMonitoringContext {
  startInteraction: Function;
  endInteraction?: Function;
}

export const monitoringDefaultValue = {
  startInteraction: () => {},
  endInteraction: () => {},
};

export const MonitoringContext = React.createContext<IMonitoringContext>(
  monitoringDefaultValue,
);

export function useMonitoring(): IMonitoringContext {
  return React.useContext(MonitoringContext);
}

export function useSingleEndInteraction(
  interactionName: string,
  entityId: string,
) {
  const { endInteraction } = useMonitoring();

  React.useEffect(() => {
    endInteraction(interactionName, entityId);
  }, [entityId]);

  return null;
}

export const MonitoringConsumer = MonitoringContext.Consumer;
