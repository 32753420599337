import React from 'react';

import { ChallengeServerlessData } from '../../api/challenges/challenge.api';
import {
  ChallengeSection,
  ChallengeStep,
} from '@wix/ambassador-challenges-v1-challenge/types';

export interface IChallengeDataContext {
  isDescriptionShown: boolean;
  challengeData?: ChallengeServerlessData;
  challengeSections?: ChallengeSection[];
  challengeSteps?: ChallengeStep[];
  requestChallengeSections?: Function;
  requestChallengeSteps?: Function;
  isMyWalletInstalled: boolean;
  isProfileInstalled: boolean;
  profileUrl: string;
}

export const challengeDataDefaultValue = {
  isDescriptionShown: false,
  challengeData: null,
  challengeSections: null,
  challengeSteps: null,
  requestChallengeSections: null,
  requestChallengeSteps: null,
  isMyWalletInstalled: false,
  isProfileInstalled: false,
  profileUrl: null,
};

export const ChallengeDataContext = React.createContext<IChallengeDataContext>(
  challengeDataDefaultValue,
);

export function useChallengeData(): IChallengeDataContext {
  return React.useContext(ChallengeDataContext);
}

export const ChallengeDataConsumer = ChallengeDataContext.Consumer;
