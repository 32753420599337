import React from 'react';
import flatten from 'lodash/flatten';

import {
  IIsStepHiddenContext,
  IsStepHiddenContext,
} from './IsStepHiddenContext';
import { ChallengeDataContext } from '../ChallengeDataProvider/ChallengeDataContext';
import { ParticipantSectionsContext } from '../ParticipantSections/ParticipantSectionsContext';
import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';
import utils from '../../components/ParticipantPage/Widget/views/utils';
import { useParticipantSteps } from '../ParticipantStepsDataProvider/ParticipantStepsContext';
import { FCWithChildren } from '@wix/challenges-web-library';

export const IsStepHiddenProvider: FCWithChildren<IIsStepHiddenContext> = (
  props,
) => {
  const { challengeData } = React.useContext(ChallengeDataContext);
  const { listParticipantSections = [] } = React.useContext(
    ParticipantSectionsContext,
  );
  const { participantSteps } = useParticipantSteps();

  const isSections = listParticipantSections.length;
  const flatStepsList = isSections
    ? flatten(listParticipantSections.map((section) => section.steps || []))
    : participantSteps?.steps || [];

  return (
    <IsStepHiddenContext.Provider
      value={{
        isStepHidden: (step: ParticipantStep) => {
          return utils.isStepHidden(
            challengeData?.challenge,
            flatStepsList,
            step,
          );
        },
        isVisibleStepLockedForComplete: (step: ParticipantStep) => {
          return utils.isVisibleStepLockedForComplete(
            challengeData?.challenge,
            flatStepsList,
            step,
          );
        },
      }}
    >
      {props.children}
    </IsStepHiddenContext.Provider>
  );
};
