import React from 'react';
import { ChallengesListDataContext } from './ChallengesListContext';
import { IChallengesListDataProps } from './challengesListDataProviderPropsMap';
import { FCWithChildren } from '@wix/challenges-web-library';

export const ChallengesListDataProvider: FCWithChildren<
  IChallengesListDataProps
> = (props) => {
  return (
    <ChallengesListDataContext.Provider
      value={{
        challengesListData: props.challengesListData,
        programPageURI: props.programPageURI,
      }}
    >
      {props.children}
    </ChallengesListDataContext.Provider>
  );
};
