import React from 'react';

import {
  ParticipantSection,
  ParticipantStep,
  ListParticipantStepsResponse,
} from '@wix/ambassador-challenges-v1-participant/types';
import { UpdateParticipantStepStatusPayload } from '../ParticipantStepsDataProvider/ParticipantStepsContext';

export interface IParticipantSectionsContext {
  selectedSection?: ParticipantSection;
  isListParticipantSectionsRequestInProgress: boolean;
  listParticipantSections: ParticipantSection[];
  participantSteps: ListParticipantStepsResponse;
  updateParticipantSections(): void;
  selectedStep?: ParticipantStep;
  updateParticipantSteps(
    currentDate?: Date,
    includeAll?: boolean,
  ): Promise<void>;
  isParticipantStepsLoading: boolean;
  updateParticipantStepStatus(
    payload: UpdateParticipantStepStatusPayload,
  ): void;
}

export const participantSectionsContextDefaultValue: IParticipantSectionsContext =
  {
    isParticipantStepsLoading: false,
    updateParticipantStepStatus(): void {},
    updateParticipantSteps(): Promise<void> {
      return Promise.resolve(undefined);
    },
    selectedSection: undefined,
    isListParticipantSectionsRequestInProgress: false,
    listParticipantSections: null,
    participantSteps: null,
    updateParticipantSections: null,
  };

export const ParticipantSectionsContext =
  React.createContext<IParticipantSectionsContext>(
    participantSectionsContextDefaultValue,
  );

export const useSections = () => React.useContext(ParticipantSectionsContext);

export const ParticipantSectionsConsumer = ParticipantSectionsContext.Consumer;
