import { RolesState } from '../../Widget/settingsEvents';
import { SettingsParamType } from '@wix/tpa-settings';
import { ImageShape } from '../../../ChallengesList/Settings/challengeSettings/challengeSettings.types';
import { SettingsKeysAbbreviation } from '../constants/settingsKeysAbbreviation';
import { SectionAbbreviation } from '../tabs/Sections/components/VisitorV2SectionsTabContent/constants/sectionIds';

export enum TextAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum SidebarPlacement {
  left = 'left',
  right = 'right',
}
export enum MobileSidebarPlacement {
  top = 'top',
  bottom = 'bottom',
}

export enum ImageAlignment {
  left = 'left',
  right = 'right',
}

export enum VerticalImageAlignment {
  top = 'top',
  bottom = 'bottom',
}

export enum VisitorPageLayout {
  classic = 'classic',
  sideBySide = 'sideBySide',
}

export enum ImageRatio {
  HD = 'hd',
  Standard = 'standard',
  Square = 'square',
}
export enum CropSelection {
  TopLeft = 'TOP_LEFT',
  top = 'TOP',
  TopRight = 'TOP_RIGHT',
  Left = 'LEFT',
  Middle = 'MIDDLE',
  Right = 'RIGHT',
  BottomLeft = 'BOTTOM_LEFT',
  Bottom = 'BOTTOM',
  BottomRight = 'BOTTOM_RIGHT',
}

export interface ISidebarLayoutSettings {
  sidebarLayoutSpace: SettingsParamType.Number;
  sidebarLayoutTextAlignment: TextAlignment;
  sidebarLayoutDisplayChallengeDate: SettingsParamType.Boolean;
  sidebarLayoutDisplayChallengeDuration: SettingsParamType.Boolean;
  sidebarLayoutDisplayChallengeSteps: SettingsParamType.Boolean;
  sidebarLayoutDisplayChallengeParticipants: SettingsParamType.Boolean;
}

export type IChallengeSettings = ISidebarLayoutSettings & {
  [SettingsKeysAbbreviation.visitorPageBodySectionsOrder]: {
    order: SectionAbbreviation[];
  };
  [SettingsKeysAbbreviation.visitorPageSidebarSectionsOrder]: {
    order: SectionAbbreviation[];
  };
  [SettingsKeysAbbreviation.visitorPageShareTitle]: SettingsParamType.Text;
  [SettingsKeysAbbreviation.visitorPageToggleShareTitle]: SettingsParamType.Boolean;
  [SettingsKeysAbbreviation.visitorPageShareShowInSidebar]: SettingsParamType.Boolean;
  [SettingsKeysAbbreviation.visitorPageGroupDiscussionTitle]: SettingsParamType.Text;
  [SettingsKeysAbbreviation.visitorPageToggleGroupDiscussionTitle]: SettingsParamType.Boolean;
  [SettingsKeysAbbreviation.visitorPageGroupDiscussionShowInSidebar]: SettingsParamType.Boolean;
  [SettingsKeysAbbreviation.visitorPageShowGroupImage]: SettingsParamType.Boolean;
  [SettingsKeysAbbreviation.visitorPagePriceJoinButtonTitle]: SettingsParamType.Text;
  [SettingsKeysAbbreviation.visitorPageTogglePriceJoinButtonTitle]: SettingsParamType.Boolean;
  [SettingsKeysAbbreviation.visitorPagePriceTitle]: SettingsParamType.Text;
  [SettingsKeysAbbreviation.visitorPageTogglePriceTitle]: SettingsParamType.Boolean;
  [SettingsKeysAbbreviation.visitorPagePriceShowInSidebar]: SettingsParamType.Boolean;
  [SettingsKeysAbbreviation.visitorPageInstructorTitle]: SettingsParamType.Text;
  [SettingsKeysAbbreviation.visitorPageToggleInstructorTitle]: SettingsParamType.Boolean;
  [SettingsKeysAbbreviation.visitorPageStartDayJoinButtonTitle]: SettingsParamType.Text;
  [SettingsKeysAbbreviation.visitorPageToggleStartDayJoinButtonTitle]: SettingsParamType.Boolean;
  [SettingsKeysAbbreviation.visitorPageStartDayTitle]: SettingsParamType.Text;
  [SettingsKeysAbbreviation.visitorPageToggleStartDayTitle]: SettingsParamType.Boolean;
  [SettingsKeysAbbreviation.visitorPageStartDayShowInSidebar]: SettingsParamType.Boolean;
  [SettingsKeysAbbreviation.visitorPageInstructorShowInSidebar]: SettingsParamType.Boolean;
  [SettingsKeysAbbreviation.visitorPageAboutTitle]: SettingsParamType.Text;
  [SettingsKeysAbbreviation.visitorPageToggleAboutTitle]: SettingsParamType.Boolean;
  [SettingsKeysAbbreviation.visitorPageAboutJoinButtonTitle]: SettingsParamType.Text;
  [SettingsKeysAbbreviation.visitorPageToggleAboutJoinButtonTitle]: SettingsParamType.Boolean;
  [SettingsKeysAbbreviation.visitorPageAboutInstructorImageShape]: ImageShape;
  visitorPageBodySidebarTextAlignment: TextAlignment;
  visitorPageBodySidebarPlacement: SidebarPlacement;
  visitorPageBodyMobileSidebarPlacement: MobileSidebarPlacement;
  visitorPageBodyEnableSidebar: SettingsParamType.Boolean;
  visitorPageLayout: VisitorPageLayout;
  visitorPageImageAlignment: ImageAlignment;
  visitorPageVerticalImageAlignment: VerticalImageAlignment;
  editorParticipantRole: RolesState;
  headerTextAlignment: TextAlignment;
  contentTextAlignment: TextAlignment;
  imageRatio: ImageRatio;
  cropSelection: CropSelection;
  displayHeaderDate: SettingsParamType.Boolean;
  displayHeaderPrice: SettingsParamType.Boolean;
  displayHeaderImage: SettingsParamType.Boolean;
  displayChallengeDuration: SettingsParamType.Boolean;
  displayChallengeSteps: SettingsParamType.Boolean;
  displayChallengeParticipants: SettingsParamType.Boolean;
  displayOwner: boolean;
  displayOneApp: boolean;
  displayDivider: boolean;
  displayTopJoinButton: boolean;
  displayGroup: SettingsParamType.Boolean;
  displayRewards: SettingsParamType.Boolean;
  displayRewardsForVisitor: SettingsParamType.Boolean;
  displayPriceForFreePrograms: boolean;
  socialSharingIsEnabled: boolean;
  socialSharingForParticipantIsEnabled: boolean;
  textJoinButton: SettingsParamType.Text;
  completeButtonTxt: SettingsParamType.Text;
  // challenge agenda at visitor page
  agendaIsEnabled: boolean;
  agendaShowTitle: boolean;
  agendaTitleText: SettingsParamType.Text;
  agendaStepsShown: number;
  agendaShowMoreText: SettingsParamType.Text;
  aboutVstText: SettingsParamType.Text;
  overviewTabDisplay: boolean;
};
