import React from 'react';

import { ILocationContext, LocationContext } from './LocationContext';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface ILocationProviderProps extends ILocationContext {}

export const LocationProvider: FCWithChildren<ILocationProviderProps> = (
  props,
) => {
  return (
    <LocationContext.Provider
      value={{
        goToExternalUrl: props.goToExternalUrl,
        baseUrl: props.baseUrl,
        query: props.query,
        location: props.location,
        path: props.path,
        goToPage: props.goToPage,
      }}
    >
      {props.children}
    </LocationContext.Provider>
  );
};
