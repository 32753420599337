import React from 'react';

export interface IDefaultBIParams {
  bsi?: string;
  metaSiteId?: string;
  visitorId?: string;
  appId?: string;
  instanceId?: string;
  siteOwnerId?: string;
}
export interface IGeneralDataContext {
  isFullWidthLayout?: boolean;
  instance: string;
  instanceId: string;
  host?: any;
  msid: string;
  defaultBIParams: IDefaultBIParams;
  startTimeOfRender: number;
  regionalSettings: string;
}

export const GeneralDataContext = React.createContext<IGeneralDataContext>({
  isFullWidthLayout: false,
  instanceId: null,
  instance: null,
  host: null,
  msid: null,
  defaultBIParams: null,
  startTimeOfRender: null,
  regionalSettings: null,
});

export const useGeneralData = () => React.useContext(GeneralDataContext);

export const GeneralDataContextConsumer = GeneralDataContext.Consumer;
