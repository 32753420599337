import React from 'react';
import { ChallengeDataContext } from './ChallengeDataContext';
import { IChallengeDataProps } from './helpers';
import { FCWithChildren } from '@wix/challenges-web-library';

export const ChallengeDataProvider: FCWithChildren<IChallengeDataProps> = (
  props,
) => {
  return (
    <ChallengeDataContext.Provider
      value={{
        isDescriptionShown: props.isDescriptionShown,
        challengeData: props.challengeData,
        challengeSections: props.challengeSections,
        challengeSteps: props.challengeSteps,
        requestChallengeSections: props.requestChallengeSections,
        requestChallengeSteps: props.requestChallengeSteps,
        isMyWalletInstalled: props.isMyWalletInstalled,
        isProfileInstalled: props.isProfileInstalled,
        profileUrl: props.profileUrl,
      }}
    >
      {props.children}
    </ChallengeDataContext.Provider>
  );
};
