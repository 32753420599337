import React from 'react';
import { IProvidersProps } from './interfaces';
import { withWixSDKWidget } from '../../services/withWixSDK';
import {
  IWixSDKViewerEnvironmentContext,
  useBi,
  useCurrencyFormatter,
} from '@wix/yoshi-flow-editor';
import { UserProvider } from '../User/UserProvider';
import { LocationProvider } from '../Location/LocationProvider';
import { ChallengeDataProvider } from '../ChallengeDataProvider/challengeDataProvider';
import { GeneralDataProvider } from '../GeneralDataProvider/GeneralDataProvider';
import { ChallengesListDataProvider } from '../ChallengesListDataProvider/challengesListDataProvider';
import { InviteLinkProvider } from '../InviteLink/InviteLinkContext';
import { PaidPlansProvider } from '../PaidPlans/paidPlansContext';
import { ToastProvider } from '../ToastContext/ToastProvider';
import { ParticipantSectionsProvider } from '../ParticipantSections/ParticipantSectionsProvider';
import { ResolveStepDataProvider } from '../ResolveStep/ResolveStepDataProvider';
import { SettingsEventsProvider } from '../SettingsEvents/SettingsEvents';
import { IsStepHiddenProvider } from '../IsStepHidden/IsStepHiddenProvider';
import { LoadingProvider } from '../Loading/LoadingContext';
import { LocalStorageProvider } from '../LocalStorage/LocalStorageProvider';
import { FullscreenProvider } from '../Fullscreen/FullscreenProvider';
import { VIEW_MODE } from '../../config/constants';
import {
  memberWebAppScreenOpen,
  memberWebWidgetShown,
} from '@wix/bi-logger-challenges-member-web/v2';
import { BadgesProvider } from '../Badges/BadgesContext';
import { SocialGroupsProvider } from '../SocialGroups/SocialGroupsContext';
import { FileUploadProvider } from '../FileUpload/FileUploadContext';
import { MonitoringProvider } from '../Monitoring/MonitoringProvider';
import {
  FCWithChildren,
  setCurrencyFormatter,
} from '@wix/challenges-web-library';
import { useIsFullWithInViewer } from '../../services/hooks/useIsFullWidthInViewer';
import { FormViewerContext } from '@wix/form-viewer/widget';
import { QuizProvider } from '../Quiz/QuizProvider';
import { getCountryCode } from '../../selectors/getCountryCode';

export type IMainProvidersProps = IProvidersProps &
  IWixSDKViewerEnvironmentContext & {
    Component: any;
  };
export const MainProviders: FCWithChildren<IMainProvidersProps> = (props) => {
  const { Component } = props;
  const bi = useBi();
  const countryCode = getCountryCode(props.regionalSettings);
  const currencyFormatter = useCurrencyFormatter({
    country: countryCode,
  });

  setCurrencyFormatter(currencyFormatter);
  const isFullWidth = useIsFullWithInViewer();

  React.useEffect(() => {
    bi?.report?.(memberWebAppScreenOpen({}));

    if (props.host?.viewMode === VIEW_MODE.Site) {
      const now = Date.now();

      bi?.report?.(
        memberWebWidgetShown({
          timeSpent: Math.max(now - props.startTimeOfRender, 0),
          itemsCount: props.challengesListData?.totalCount || 1,
        }),
      );
    }
  }, []);

  return (
    <GeneralDataProvider
      host={props.host}
      isFullWidthLayout={isFullWidth}
      instanceId={props.instanceId}
      instance={props.instance}
      msid={props.msid}
      defaultBIParams={props.defaultBIParams}
      startTimeOfRender={props.startTimeOfRender}
      regionalSettings={props.regionalSettings}
    >
      <LocationProvider
        baseUrl={props.baseUrl}
        path={props.path}
        query={props.query}
        location={props.location}
        goToPage={props.goToPage}
        goToExternalUrl={props.goToExternalUrl}
      >
        <LocalStorageProvider
          requestItem={props.requestItem}
          setItem={props.setItem}
          storage={props.storage}
        >
          <UserProvider
            incrementParticipantsCompletedStepSummary={
              props.incrementParticipantsCompletedStepSummary
            }
            updateParticipant={props.updateParticipant}
            user={props.user}
            userType={props.userType}
            isParticipantInSuspendedState={props.isParticipantInSuspendedState}
            participant={props.participant}
            promptLogin={props.promptLogin}
            join={props.join}
            cancelJoinRequest={props.cancelJoinRequest}
            leaveTheChallenge={props.leaveTheChallenge}
            userTypeHandlers={props.userTypeHandlers}
          >
            <ChallengeDataProvider
              isDescriptionShown={props.isDescriptionShown}
              challengeData={props.challengeData}
              challengeSections={props.challengeSections}
              challengeSteps={props.challengeSteps}
              requestChallengeSections={props.requestChallengeSections}
              requestChallengeSteps={props.requestChallengeSteps}
              isMyWalletInstalled={props.isMyWalletInstalled}
              isProfileInstalled={props.isProfileInstalled}
              profileUrl={props.profileUrl}
            >
              <ChallengesListDataProvider
                challengesListData={props.challengesListData}
                programPageURI={props.programPageURI}
              >
                <InviteLinkProvider goToInviteLink={props.goToInviteLink}>
                  <PaidPlansProvider
                    eligiblePlans={props.eligiblePlans}
                    userPaidPlans={props.userPaidPlans}
                  >
                    <ToastProvider
                      toastMessage={props.toastMessage}
                      clearMessage={props.clearMessage}
                    >
                      <ParticipantSectionsProvider
                        selectedSection={props.selectedSection}
                        participantSteps={props.participantSteps}
                        isListParticipantSectionsRequestInProgress={
                          props.isListParticipantSectionsRequestInProgress
                        }
                        listParticipantSections={props.listParticipantSections}
                        updateParticipantSections={
                          props.updateParticipantSections
                        }
                        selectedStep={props.selectedStep}
                        updateParticipantSteps={props.updateParticipantSteps}
                        isParticipantStepsLoading={
                          props.isParticipantStepsLoading
                        }
                        updateParticipantStepStatus={
                          props.updateParticipantStepStatus
                        }
                      >
                        <ResolveStepDataProvider
                          isResolveStepRequestInProgress={
                            props.isResolveStepRequestInProgress
                          }
                          resolveStep={props.resolveStep}
                          resolveStepData={props.resolveStepData}
                          resolveStepError={props.resolveStepError}
                        >
                          <SettingsEventsProvider
                            buttonState={props.buttonState}
                            categoriesTabsState={props.categoriesTabsState}
                            pageRole={props.pageRole}
                            LayoutSelectedTab={
                              props.shownTab || props.LayoutSelectedTab
                            }
                            shownTab={props.shownTab || props.LayoutSelectedTab}
                            sidebarLayoutMobileScreen={
                              props.sidebarLayoutMobileScreen
                            }
                          >
                            <IsStepHiddenProvider
                              isStepHidden={props.isStepHidden}
                              isVisibleStepLockedForComplete={
                                props.isVisibleStepLockedForComplete
                              }
                            >
                              <LoadingProvider
                                showLoader={props.showLoader}
                                hideLoader={props.hideLoader}
                                isLoading={props.isLoading}
                              >
                                <FullscreenProvider toggleFullscreen={null}>
                                  <BadgesProvider
                                    badges={
                                      props.challengeData?.badgesData?.badges ||
                                      []
                                    }
                                  >
                                    <SocialGroupsProvider
                                      isGroupsInstalled={
                                        props.isGroupsInstalled
                                      }
                                      groupPageUrl={props.groupPageUrl}
                                      socialGroup={
                                        props.challengeData?.groupData?.group ||
                                        null
                                      }
                                    >
                                      <FileUploadProvider
                                        itemsLoading={props.itemsLoading}
                                        setItemsLoading={props.setItemsLoading}
                                        uploadedFile={props.uploadedFile}
                                        uploadFile={props.uploadFile}
                                        clearUploadedFile={
                                          props.clearUploadedFile
                                        }
                                      >
                                        <MonitoringProvider
                                          startInteraction={
                                            props.startInteraction
                                          }
                                          endInteraction={props.endInteraction}
                                        >
                                          <QuizProvider
                                            controllerIsReady={
                                              props.controllerIsReady
                                            }
                                            initController={
                                              props.initController
                                            }
                                            sendSubmission={
                                              props.sendSubmission
                                            }
                                            sendSubmissionInProgress={
                                              props.sendSubmissionInProgress
                                            }
                                            lastSubmissionId={
                                              props.lastSubmissionId
                                            }
                                            getSubmission={props.getSubmission}
                                            getSubmissionInProgress={
                                              props.getSubmissionInProgress
                                            }
                                            submission={props.submission}
                                            earnedGrade={props.earnedGrade}
                                            resubmitTheQuiz={
                                              props.resubmitTheQuiz
                                            }
                                            clearQuizStore={
                                              props.clearQuizStore
                                            }
                                            pagesData={props.pagesData}
                                            setPagesData={props.setPagesData}
                                          >
                                            <FormViewerContext
                                              {...(props as any)}
                                            >
                                              <Component {...props} />
                                            </FormViewerContext>
                                          </QuizProvider>
                                        </MonitoringProvider>
                                      </FileUploadProvider>
                                    </SocialGroupsProvider>
                                  </BadgesProvider>
                                </FullscreenProvider>
                              </LoadingProvider>
                            </IsStepHiddenProvider>
                          </SettingsEventsProvider>
                        </ResolveStepDataProvider>
                      </ParticipantSectionsProvider>
                    </ToastProvider>
                  </PaidPlansProvider>
                </InviteLinkProvider>
              </ChallengesListDataProvider>
            </ChallengeDataProvider>
          </UserProvider>
        </LocalStorageProvider>
      </LocationProvider>
    </GeneralDataProvider>
  );
};

MainProviders.displayName = 'ChallengesProviders';

export function withProviders(ProvidedComponent) {
  return withWixSDKWidget(function (props: any) {
    return <MainProviders {...props} Component={ProvidedComponent} />;
  });
}
