import React from 'react';
import { IGeneralDataContext, GeneralDataContext } from './GeneralDataContext';
import { FCWithChildren } from '@wix/challenges-web-library';

export const GeneralDataProvider: FCWithChildren<IGeneralDataContext> = (
  props,
) => {
  return (
    <GeneralDataContext.Provider
      value={{
        host: props.host,
        isFullWidthLayout: props.isFullWidthLayout,
        instanceId: props.instanceId,
        instance: props.instance,
        msid: props.msid,
        defaultBIParams: props.defaultBIParams,
        startTimeOfRender: props.startTimeOfRender,
        regionalSettings: props.regionalSettings,
      }}
    >
      {props.children}
    </GeneralDataContext.Provider>
  );
};
